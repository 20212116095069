import React from 'react'
import {Regex} from "../components/Ruby/container/regex"

export const Ruby = () => {
  return (
    <div>
      <Regex />
    </div>
  )
}
